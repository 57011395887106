import React from "react";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerBackground01 from "../Assets/home-banner-background-1.png";
import BannerBackground02 from "../Assets/home-banner-background-2.png";
import BannerSquareBackground from "../Assets/home-banner-square-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import GooglePlayBadge from "../Assets/google-play-badge.png";
import BannerImage01 from "../Assets/home-banner-image-1.png";
import BannerImage01Title from "../Assets/home-banner-image-1-title.png";
import BannerImage01Iphone from "../Assets/home-banner-image-1-iphone.png";
import BannerImage02 from "../Assets/home-banner-image-2.png";
import BannerImage03 from "../Assets/home-banner-image-3.png";
import BannerImage04 from "../Assets/home-banner-image-4.png";
import BannerImage05 from "../Assets/home-banner-image-5.png";
import BannerImage06 from "../Assets/home-banner-image-6.png";

const Home = () => {
  const openGoogleApp = () => {
    // 구글 앱으로 이동하는 URL
    // const googleAppURL =
    //   "https://play.google.com/store/apps/details?id=com.google.android.apps.maps";
    const ilovehomeUrl =
      "https://play.google.com/store/apps/details?id=com.ilovehomeapp&hl=ko-KR";

    // 새 탭에서 구글 앱 링크 열기
    window.open(ilovehomeUrl, "_blank");
  };

  return (
    <div className="home-container" id="Home">
      <Navbar />

      <div
        className="home-banner-container"
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          flexDirection: "column",
          // backgroundColor: "yellow",
          justifyContent: "center",
        }}
      >
        <div
          className="home-banner-container"
          style={{
            width: "100%",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#FEFFBB",
          }}
        >
          <div>
            <div>
              <img src={BannerImage01Title} alt="" style={{ width: "500px" }} />
            </div>
            <div>
              <img
                src={GooglePlayBadge}
                alt=""
                style={{
                  width: "120px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  openGoogleApp();
                }}
              />
            </div>
          </div>
          <div>
            <img src={BannerImage01Iphone} alt="" />
          </div>
        </div>
        <div
          className="home-image-section"
          style={{ paddingBottom: "0px", backgroundColor: "#C4F1FB" }}
        >
          <img src={BannerImage02} alt="" />
        </div>
        <div
          className="home-image-section"
          style={{ paddingBottom: "0px", paddingTop: "50px" }}
        >
          <img src={BannerImage03} alt="" />
        </div>
        <div
          style={{
            alignItems: "flex-start",
            width: "100%",
            paddingLeft: "10px",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="home-image-section"
            style={{
              marginTop: "-20px",
              paddingBottom: "5px",
              width: "150px",
            }}
          >
            <img
              src={BannerImage04}
              alt=""
              onClick={() => {
                window.location.href =
                  "https://www.mois.go.kr/frt/sub/a06/b06/hometownLovedonation/screen.do";
              }}
            />
          </div>
          <div
            className="home-image-section"
            style={{ paddingBottom: "100px", width: "150px" }}
          >
            <img
              src={BannerImage05}
              alt=""
              onClick={() => {
                console.log("### onClick");
                window.location.href =
                  "https://www.ilovegohyang.go.kr/goods/index.html";
              }}
            />
          </div>
        </div>
        <div
          style={{
            alignItems: "flex-start",
            width: "100%",
            paddingLeft: "10px",
            justifyContent: "flex-start",
            backgroundColor: "#F2F2F2",
          }}
        >
          <div
            className="home-image-section"
            style={{
              paddingBottom: "100px",
              // paddingLeft: "10px",
              // paddingRight: "10px",
            }}
          >
            <div
              style={{
                paddingBottom: "0px",
                paddingTop: "50px",
                // paddingLeft: "10px",
                // paddingRight: "10px",
                width: "200px",
                justifyContent: "flex-start",
              }}
            >
              <img src={BannerImage06} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
