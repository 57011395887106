import logo from "./logo.svg";
import "./App.css";
import { StickyProvider } from "./Contexts/App/app.provider";
import Home from "./Components/Home";
import About from "./Components/About";
import Works from "./Components/Works";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Layout from "./Components/layout";

function App() {
  return (
    <div className="App">
      <StickyProvider>
        {/* <Layout isMainHeader={true}> */}
        <Home />
        {/* <About />
        <Works />
        <Testimonial />
        <Contact />
        <Footer /> */}
        {/* </Layout> */}
      </StickyProvider>
    </div>
  );
}

export default App;
